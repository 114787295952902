export const headerSearchBtn = () => {
    const headerSearchBtn = jQuery('.headerSearchBtn');
    const postUrl = '/search/key/result/';

    headerSearchBtn.on('click', function () {
        let keyword = $(this).prev().val();
        let result = '';
        if (keyword.length === 0) return false;

        if (keyword.match(/^\d{3}-?\d{4}$/)) {
            $.ajax({
                type: 'GET',
                url: "https://zipcloud.ibsnet.co.jp/api/search",
                data: { zipcode: keyword },
                async: false,
                dataType: 'json'
            }).done(function (dataj) {
                result = dataj.results[0].address1 + dataj.results[0].address2;
            });
        }

        location.href = postUrl + '?keyword=' + result;
        return false;
    });
}