export const templateElementScrollAction = () => {
    const templateElementList = $('#wp-admin-bar-current_template').children().children().children();

    if (templateElementList.length === 0) return false;

    // クリックイベントをリスト全体に対して1回だけ登録
    templateElementList.on('click', function (e) {
        const targetId = $(this).text().replace(/\.php$/, ""); // 拡張子を削除
        const targetElement = $('[data-scroll="' + targetId + '"]');

        if (targetElement.length >= 1) {
            const offset = targetElement.offset().top - 50; // 固定ヘッダー分調整（必要に応じて）
            $('html, body').stop().animate({
                scrollTop: Math.min(offset, $(document).height() - $(window).height()) // 範囲を確認
            }, 500);
        } else {
            console.error('Target element not found!');
        }
    });
}