//アニメーション終了フラグ
var is_m1_anim = false;
var is_m2_anim = false;
var is_m3_anim = false;

//css指定の座標
var m1_pos = {
    r1_x: 0,
    r1_y: 0,
    r2_x: 0,
    r2_y: 0,
    r3_x: 0,
    r3_y: 0
}
var m2_pos = {
    r1_x: 0,
    r1_y: 0,
    r2_x: 0,
    r2_y: 0,
    r3_x: 0,
    r3_y: 0
}
var m3_pos = {
    r1_x: 0,
    r1_y: 0,
    r2_x: 0,
    r2_y: 0,
    r3_x: 0,
    r3_y: 0
}

///////////////
//飛んでくる角度
///////////////

//ラジアン
var r1_rad = 0;
var r2_rad = 90;
var r3_rad = 180;

///////////////
//円運動設定
///////////////

//回転半径
var r1_r = 8;
var r2_r = 8;
var r3_r = 8;

//回転スピード
var r1_s = 800;
var r2_s = 800;
var r3_s = 800;

function animCircle(num) {
    var area;
    var pos;
    if (num === 1) {
        $area = $('.m1');
        pos = m1_pos;
    }
    else if (num === 2) {
        $area = $('.m2');
        pos = m2_pos;
    }
    else if (num === 3) {
        $area = $('.m3');
        pos = m3_pos;
    }
    var $r1_el = $area.find('.r1');
    var st_x = pos['r1_x'] + (Math.cos(r1_rad) * 200);
    var st_y = pos['r1_y'] + (Math.sin(r1_rad) * 200);
    var ed_x = pos['r1_x'] + (Math.cos(r1_rad) * r1_r);
    var ed_y = pos['r1_y'] + (Math.sin(r1_rad) * r1_r);
    $r1_el.css({ 'top': st_y + 'px', 'left': st_x + 'px' });
    $r1_el.animate(
        {
            top: ed_y,
            left: ed_x,
            opacity: 0.5
        },
        {
            duration: 600,
            easing: 'easeOutCubic',

        }
    )

    var $r2_el = $area.find('.r2');
    var st_x = pos['r2_x'] + (Math.cos(r2_rad) * 200);
    var st_y = pos['r2_y'] + (Math.sin(r2_rad) * 200);
    var ed_x = pos['r2_x'] + (Math.cos(r2_rad) * r2_r);
    var ed_y = pos['r2_y'] + (Math.sin(r2_rad) * r2_r);
    $r2_el.css({ 'top': st_y + 'px', 'left': st_x + 'px' });
    $r2_el.animate(
        {
            top: ed_y,
            left: ed_x,
            opacity: 0.4
        },
        {
            duration: 600,
            easing: 'easeOutCubic',
        }
    )

    var $r3_el = $area.find('.r3');
    var st_x = pos['r3_x'] + (Math.cos(r3_rad) * 200);
    var st_y = pos['r3_y'] + (Math.sin(r3_rad) * 200);
    var ed_x = pos['r3_x'] + (Math.cos(r3_rad) * r3_r);
    var ed_y = pos['r3_y'] + (Math.sin(r3_rad) * r3_r);

    $r3_el.css({ 'top': st_y + 'px', 'left': st_x + 'px' });
    $r3_el.animate(
        {
            top: ed_y,
            left: ed_x,
            opacity: 0.3
        },
        {
            duration: 600,
            easing: 'easeOutCubic',
            complete: function () {
                rotateCircle(num);
            }
        }
    )
}

function rotateCircle(num) {

    var area;
    var pos;
    if (num === 1) {
        area = document.querySelector('.m1');
        pos = m1_pos;
    }
    else if (num === 2) {
        area = document.querySelector('.m2');
        pos = m2_pos;
    }
    else if (num === 3) {
        area = document.querySelector('.m3');
        pos = m3_pos;
    }
    var t1 = new Date().getTime() / r1_s;
    var r1_el = area.querySelector('.r1');
    setInterval(function () {
        var t = new Date().getTime() / r1_s - t1 + r1_rad;
        var x = pos['r1_x'] + (Math.cos(t) * r1_r);
        var y = pos['r1_y'] + (Math.sin(t) * r1_r);
        var s = 'top:' + y + 'px;'
        s += 'left:' + x + 'px;'
        s += 'opacity:0.5;'
        r1_el.setAttribute('style', s);
    }, 10);

    var t2 = new Date().getTime() / r2_s;
    var r2_el = area.querySelector('.r2');
    setInterval(function () {
        var t = new Date().getTime() / r2_s - t2 + r2_rad;
        var x = pos['r2_x'] + (Math.cos(t) * r2_r);
        var y = pos['r2_y'] + (Math.sin(t) * r2_r);
        var s = 'top:' + y + 'px;'
        s += 'left:' + x + 'px;'
        s += 'opacity:0.4;'
        r2_el.setAttribute('style', s);
    }, 10);

    var t3 = new Date().getTime() / r3_s;
    var r3_el = area.querySelector('.r3');
    setInterval(function () {
        var t = new Date().getTime() / r3_s - t3 + r3_rad;
        var x = pos['r3_x'] + (Math.cos(t) * r3_r);
        var y = pos['r3_y'] + (Math.sin(t) * r3_r);
        var s = 'top:' + y + 'px;'
        s += 'left:' + x + 'px;'
        s += 'opacity:0.3;'
        r3_el.setAttribute('style', s);
    }, 10);
}

function rotateCircleWhite() {

    var $area = $('.img_area .tag');
    $area.each(function () {
        var $that = $(this);
        var t1 = new Date().getTime() / r1_s;
        var $r1_el = $that.find('.r1');
        setInterval(function () {
            var t = new Date().getTime() / r1_s - t1 + r1_rad;
            var x = 4 + (Math.cos(t) * r1_r);
            var y = 8 + (Math.sin(t) * r1_r);
            var s = 'top:' + y + 'px;'
            s += 'left:' + x + 'px;'
            $r1_el.attr('style', s);
        }, 10);
        var t2 = new Date().getTime() / r2_s;
        var $r2_el = $that.find('.r2');
        setInterval(function () {
            var t = new Date().getTime() / r2_s - t2 + r2_rad;
            var x = 6 + (Math.cos(t) * r2_r);
            var y = 10 + (Math.sin(t) * r2_r);
            var s = 'top:' + y + 'px;'
            s += 'left:' + x + 'px;'
            $r2_el.attr('style', s);
        }, 10);
        var t3 = new Date().getTime() / r3_s;
        var $r3_el = $that.find('.r3');
        setInterval(function () {
            var t = new Date().getTime() / r3_s - t3 + r3_rad;
            var x = 8 + (Math.cos(t) * r3_r);
            var y = 12 + (Math.sin(t) * r3_r);
            var s = 'top:' + y + 'px;'
            s += 'left:' + x + 'px;'
            $r3_el.attr('style', s);
        }, 10);

    });

}


$(window).on('load', function () {

    var m1_area = document.querySelector(".m1");
    if (m1_area === null) return false;

    //センター座標取得
    var r1_el = m1_area.querySelector(".r1");
    var r1_st = window.getComputedStyle(r1_el);
    m1_pos['r1_x'] = Number(r1_st.getPropertyValue('left').split('px')[0]);
    m1_pos['r1_y'] = Number(r1_st.getPropertyValue('top').split('px')[0]);

    var r2_el = m1_area.querySelector(".r2");
    var r2_st = window.getComputedStyle(r2_el);
    m1_pos['r2_x'] = Number(r2_st.getPropertyValue('left').split('px')[0]);
    m1_pos['r2_y'] = Number(r2_st.getPropertyValue('top').split('px')[0]);

    var r3_el = m1_area.querySelector(".r3");
    var r3_st = window.getComputedStyle(r3_el);
    m1_pos['r3_x'] = Number(r3_st.getPropertyValue('left').split('px')[0]);
    m1_pos['r3_y'] = Number(r3_st.getPropertyValue('top').split('px')[0]);

    var m2_area = document.querySelector(".m2");

    //センター座標取得
    var r1_el = m2_area.querySelector(".r1");
    var r1_st = window.getComputedStyle(r1_el);
    m2_pos['r1_x'] = Number(r1_st.getPropertyValue('left').split('px')[0]);
    m2_pos['r1_y'] = Number(r1_st.getPropertyValue('top').split('px')[0]);

    var r2_el = m1_area.querySelector(".r2");
    var r2_st = window.getComputedStyle(r2_el);
    m2_pos['r2_x'] = Number(r2_st.getPropertyValue('left').split('px')[0]);
    m2_pos['r2_y'] = Number(r2_st.getPropertyValue('top').split('px')[0]);

    var r3_el = m1_area.querySelector(".r3");
    var r3_st = window.getComputedStyle(r3_el);
    m2_pos['r3_x'] = Number(r3_st.getPropertyValue('left').split('px')[0]);
    m2_pos['r3_y'] = Number(r3_st.getPropertyValue('top').split('px')[0]);


    var m3_area = document.querySelector(".m3");

    //センター座標取得
    var r1_el = m3_area.querySelector(".r1");
    var r1_st = window.getComputedStyle(r1_el);
    m3_pos['r1_x'] = Number(r1_st.getPropertyValue('left').split('px')[0]);
    m3_pos['r1_y'] = Number(r1_st.getPropertyValue('top').split('px')[0]);

    var r2_el = m1_area.querySelector(".r2");
    var r2_st = window.getComputedStyle(r2_el);
    m3_pos['r2_x'] = Number(r2_st.getPropertyValue('left').split('px')[0]);
    m3_pos['r2_y'] = Number(r2_st.getPropertyValue('top').split('px')[0]);

    var r3_el = m1_area.querySelector(".r3");
    var r3_st = window.getComputedStyle(r3_el);
    m3_pos['r3_x'] = Number(r3_st.getPropertyValue('left').split('px')[0]);
    m3_pos['r3_y'] = Number(r3_st.getPropertyValue('top').split('px')[0]);

    $(window).on('scroll', function () {
        if ($(window).scrollTop() + $(window).height() >= $('.m1').offset().top + 200) {
            if (!is_m1_anim) {
                is_m1_anim = true;
                animCircle(1);
            }
        }
        if ($(window).scrollTop() + $(window).height() >= $('.m2').offset().top + 200) {
            if (!is_m2_anim) {
                is_m2_anim = true;
                animCircle(2);
            }
        }
        if ($(window).scrollTop() + $(window).height() >= $('.m3').offset().top + 200) {
            if (!is_m3_anim) {
                is_m3_anim = true;
                animCircle(3);
            }
        }
    });
    rotateCircleWhite();
    $(window).trigger('scroll');
});